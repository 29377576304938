<template>
  <OHomepageOffer
    :title="products?.title"
    :popular-categories="categories"
    :products="products?.productsList"
    :loading="loading"
    banner-position="right"
    :banner-src="getImageUrlWithSize(bannerUrl, sizes)"
    :mobile-banner-src="getImageUrlWithSize(bannerUrl, sizes, true)"
    banner-link="wine-set"
  />
</template>

<script setup lang="ts">
import { ref, defineComponent } from 'vue'
import { useBanners } from '@/stores/banners'
import { useProductCollections } from '@/stores/product-collection'
import { getImageUrlWithSize } from '@/utils/url'

import type { PopularCategoryExtended } from '@/components/atoms/PopularCategory/types'
import type { BannerCollection } from '@/modules/nuxt-api/models/Banner'

import OHomepageOffer from '@/components/organisms/HomepageOffer/OHomepageOffer.vue'
import { useConfig } from '~/stores/config'

defineComponent({ name: 'HomeOfferBannerCollections' })

const sizes = {
  mobile: { w: '991', h: '344' },
  desktop: { w: '320', h: '638' }
}

const loading = ref(process.client)
const categories = ref()
const products = ref()
const bannerUrl = ref()
const { isMscRegion } = useConfig()

function getCategories (): PopularCategoryExtended[] {
  return [
    {
      id: 0,
      title: 'В подарок',
      image: {
        url: '/assets/images/popular-sections-index/4_110x110.png',
        mobileUrl:
          '/assets/images/popular-sections-index/4_110x110.png 110w, /assets/images/popular-sections-index/4_276x276.png 276w',
        alt: 'В подарок'
      },
      displayType: 0,
      section: {
        path: { path: '', value: 'wine-set' },
        isClosed: false
      }
    },
    {
      id: 1,
      title: 'Просекко',
      image: {
        url: '/assets/images/popular-sections-index/5_110x110.png',
        mobileUrl:
          '/assets/images/popular-sections-index/5_110x110.png 110w, /assets/images/popular-sections-index/5_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      section: {
        path: { path: '', value: 'champagnes-and-sparkling/prosecco' },
        isClosed: false
      }
    },
    {
      id: 2,
      title: 'Фруктовая водка',
      image: {
        url: '/assets/images/popular-sections-index/7_110x110.png',
        mobileUrl:
          '/assets/images/popular-sections-index/7_110x110.png 110w, /assets/images/popular-sections-index/7_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      section: {
        path: { path: '', value: 'vodka/fruit' },
        isClosed: false
      }
    },
    {
      id: 3,
      title: 'Шотландский виски',
      image: {
        url: '/assets/images/popular-sections-index/10_110x110.png',
        mobileUrl:
          '/assets/images/popular-sections-index/10_110x110.png 110w, /assets/images/popular-sections-index/10_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      section: {
        path: { path: '', value: 'whisky/scotland' },
        isClosed: false
      }
    },
    {
      id: 4,
      title: 'Крафтовое пиво',
      image: {
        url: '/assets/images/popular-sections-index/2_110x110.png',
        mobileUrl:
          '/assets/images/popular-sections-index/2_110x110.png 110w, /assets/images/popular-sections-index/2_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      section: {
        path: { path: '', value: 'beer/craft' },
        isClosed: false
      }
    },
    {
      id: 5,
      title: 'Красное вино',
      image: {
        url: '/assets/images/popular-sections-index/12_110x110.png',
        mobileUrl:
          '/assets/images/popular-sections-index/12_110x110.png 110w, /assets/images/popular-sections-index/12_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      section: {
        path: { path: '', value: 'wine/red' },
        isClosed: false
      }
    }
  ]
}

await Promise.all([
  useHydrationData('home-banners', () => useBanners().getBannersForHome(), {
    then: ({ data }) => {
      bannerUrl.value = data.value?.filter?.((banner: BannerCollection) => banner.type === 'newrtvert')?.[0]?.bannersList?.[0]?.image?.url
    },
    catch: () => undefined,
    lazy: true
  }),
  useHydrationData('home-offer-categories', getCategories, {
    then: ({ data }) => {
      categories.value = data.value
    },
    catch: () => undefined,
    lazy: true
  }),
  useHydrationData('home-product-collections', () => useProductCollections().getHomeCollections(), {
    then: ({ data }) => {
      const findData = data.value?.find(el => el.code === (isMscRegion.value ? 'promo_best_offers_msc' : 'wine_wine_4_plus_stars'))
      products.value = findData
    },
    catch: () => undefined,
    lazy: true
  })
])
  .then(() => { loading.value = false })
</script>
